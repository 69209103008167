<template>
  <v-card flat>
    <v-card-text>
      <h2 class="mb-3">ตั้งค่าปิด/เปิดระบบหลังบ้าน</h2>
<!--      <hr/>-->
<!--      <h4 class="mt-3 mb-3">โลโก้ระบบ</h4>-->
<!--      &lt;!&ndash;      main_data.main_data_logo&ndash;&gt;-->
<!--      <v-img-->
<!--        width="150"-->
<!--      :src="main_data.main_data_logo"-->
<!--      >-->

<!--      </v-img>-->
<!--      <v-file-input-->
<!--        v-model="main_data.logo_file"-->
<!--        label="โลโก้ระบบ"-->
<!--        show-size-->
<!--        accept="image/png, image/jpeg, image/bmp"-->
<!--      ></v-file-input>-->

      <v-text-field
        label="ชื่อระบบ"
        type="text"
        v-model="main_data.main_data_name"
        placeholder="ชื่อระบบ"
      ></v-text-field>
      <small
        class="validate-err"
        v-for="(err,index) in v$.main_data.main_data_name.$silentErrors"
        v-if="v$.main_data.main_data_name.$error">
        {{ err.$message }}</small>

<!--      <v-text-field-->
<!--        v-model="main_data.main_data_title_web"-->
<!--        label="title ระบบ"-->
<!--        type="text"-->
<!--        placeholder="title ระบบ"-->
<!--      ></v-text-field>-->
<!--      <small-->
<!--        class="validate-err"-->
<!--        v-for="(err,index) in v$.main_data.main_data_title_web.$silentErrors"-->
<!--        v-if="v$.main_data.main_data_title_web.$error">{{ index > 0 ? ', ' : '' }}-->
<!--        {{ err.$message }}</small>-->

<!--      <v-text-field-->
<!--        v-model="main_data.main_data_name_domain"-->
<!--        label="ชื่อโดเมน"-->
<!--        type="text"-->
<!--        placeholder="ชื่อโดเมน"-->
<!--      ></v-text-field>-->
<!--      <small-->
<!--        class="validate-err"-->
<!--        v-for="(err,index) in v$.main_data.main_data_name_domain.$silentErrors"-->
<!--        v-if="v$.main_data.main_data_name_domain.$error">{{ index > 0 ? ', ' : '' }}-->
<!--        {{ err.$message }}</small>-->

<!--      <h4 class="mt-3 mb-3">QR CODE</h4>-->
<!--      <v-img-->
<!--        width="150"-->
<!--        :src="main_data.main_data_qr_code"></v-img>-->

<!--      <v-file-input-->
<!--        v-model="main_data.qr_file"-->
<!--        label="QR CODE"-->
<!--        show-size-->
<!--        accept="image/png, image/jpeg, image/bmp"-->
<!--      >-->
<!--      </v-file-input>-->

<!--      <v-text-field-->
<!--        v-model="main_data.main_data_line_id"-->
<!--        label="Line Id"-->
<!--        type="text"-->
<!--        placeholder="Line Id"-->
<!--      ></v-text-field>-->

<!--      <small-->
<!--        class="validate-err"-->
<!--        v-for="(err,index) in v$.main_data.main_data_line_id.$silentErrors"-->
<!--        v-if="v$.main_data.main_data_line_id.$error">{{ index > 0 ? ', ' : '' }}-->
<!--        {{ err.$message }}</small>-->
<!--      <v-text-field-->
<!--        label="Facebook URL"-->
<!--        v-model="main_data.main_data_facebook"-->
<!--        type="text"-->
<!--        placeholder="Facebook URL"-->
<!--      ></v-text-field>-->

<!--      <v-text-field-->
<!--        v-model="main_data.main_data_instragram"-->
<!--        label="Instragram"-->
<!--        type="text"-->
<!--        placeholder="Instragram"-->
<!--      ></v-text-field>-->

<!--      <v-text-field-->
<!--        v-model="main_data.main_data_email"-->
<!--        label="Email Address"-->
<!--        type="text"-->
<!--        placeholder="Email Address"-->
<!--      ></v-text-field>-->
<!--      <small-->
<!--        class="validate-err"-->
<!--        v-for="(err,index) in v$.main_data.main_data_email.$silentErrors"-->
<!--        v-if="v$.main_data.main_data_email.$error">{{ index > 0 ? ', ' : '' }}-->
<!--        {{ err.$message }}</small>-->

      <v-text-field
        v-model="main_data.main_data_address"
        label="ที่อยู่"
        type="text"
        placeholder="ที่อยู่"
      ></v-text-field>
      <small
        class="validate-err"
        v-for="(err,index) in v$.main_data.main_data_address.$silentErrors"
        v-if="v$.main_data.main_data_address.$error">{{ index > 0 ? ', ' : '' }}
        {{ err.$message }}</small>

      <v-text-field
        v-model="main_data.turn_off_message"
        label="ข้อความแจ้งเตือนเปิด/ปิดระบบ"
        type="text"
        placeholder="ข้อความแจ้งเตือนเปิดปิดระบบ"
      ></v-text-field>

      <h4 class="mt-3 mb-3">เวลาเริ่มตั้งเวลาปิดระบบ *</h4>
      <Datepicker
        type="datetime"
        v-model="main_data.turn_off_start_time"
        title="เวลาเริ่มตั้งเวลาปิดระบบ"
        placeholder="เวลาเริ่มตั้งเวลาปิดระบบ"
        value-type="format"
        label="เวลาเริ่มตั้งเวลาปิดระบบ"></Datepicker>

      <h4 class="mt-3 mb-3">เวลาเริ่มตั้งเวลาเปิดระบบ *</h4>
      <Datepicker
        type="datetime"
        v-model="main_data.turn_off_end_time"
        title="เวลาเริ่มตั้งเวลาเปิดระบบ"
        placeholder="เวลาเริ่มตั้งเวลาเปิดระบบ"
        value-type="format"
        label="เวลาเริ่มตั้งเวลาเปิดระบบ"></Datepicker>

      <hr class="mt-5 mb-5">
      <v-btn color="primary" @click="save">บันทึก</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline} from '@mdi/js'
import Datepicker from "vue2-datepicker";
import {ref} from '@vue/composition-api'

// demos
import AccountSettingsAccount from './Setting.vue'
// import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
// import AccountSettingsInfo from './AccountSettingsInfo.vue'

import instance_main_data from '../../../services/mainData'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email , minLength} from '@vuelidate/validators'

export default {
  components: {
    Datepicker
    // backend
    // AccountSettingsAccount,
    // AccountSettingsSecurity,
    // AccountSettingsInfo,
  },
  setup() {
    const tab = ref('')


    return {
      v$: useVuelidate(),
      // tab,
      // tabs,
      // accountSettingData,
      // icons: {
      //   mdiAccountOutline,
      //   mdiLockOpenOutline,
      //   mdiInformationOutline,
      // },
    }
  },
  data() {
    return {
      main_data: {
        logo_file : null,
        qr_file : null,
        main_data_logo: '',
        main_data_address: '',
        main_data_email: '',
        main_data_facebook: '',
        main_data_instragram: '',
        main_data_line_id: '',
        main_data_name_domain: '',
        main_data_name: '',
        main_data_qr_code: '',
        main_data_tele: '',
        main_data_title_web: '',
        turn_off: '',
        turn_off_end_time: '',
        turn_off_message: '',
        turn_off_start_time: '',
      }
    }
  },
  validations() {
    return {
      main_data: {
        main_data_name: {required},
        main_data_line_id: {required},
        main_data_title_web: {required},
        main_data_name_domain: { required },
        main_data_email: { required , email },
        main_data_address: { required },
      }
    }
  },
  methods: {
    getMainData() {
      this.$store.state.isLoading = true
      instance_main_data.showData().then(res => {
        this.main_data = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })

    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      instance_main_data.update(this.main_data).then(res => {
        if (res.data.success){
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          this.getMainData();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'เกิดขิดพลาด !!',
            text: res.message ?? res.data.message,
          })
          this.$store.state.isLoading = false
        }
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    }
  },
  mounted() {
    this.getMainData()
  }
}
</script>
